// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Embed from "https://framerusercontent.com/modules/o1PI5S8YtkA5bP5g4dFz/Mn69eMnaMcqJ65TjxwR1/Embed.js";
import * as localizedValues from "./PuyRk62h5-0.js";
const EmbedFonts = getFonts(Embed);

const cycleOrder = ["Vr558G0tF", "d8gC7aPSu", "QyphF_D8A", "i8MkIqnHE"];

const serializationHash = "framer-MN4qU"

const variantClassNames = {d8gC7aPSu: "framer-v-579k0a", i8MkIqnHE: "framer-v-tplo4j", QyphF_D8A: "framer-v-765lxn", Vr558G0tF: "framer-v-zblopb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"1018": "Vr558G0tF", "310": "d8gC7aPSu", "Variant 3": "QyphF_D8A", BigBoy: "i8MkIqnHE"}

const getProps = ({auglurl, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Vr558G0tF", zOQouysXS: auglurl ?? props.zOQouysXS ?? "https://app.pulsmedia.is/bordi/10255/live?preview=true"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;auglurl?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, zOQouysXS, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Vr558G0tF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-zblopb", className, classNames)} data-framer-name={"1018"} layoutDependency={layoutDependency} layoutId={"Vr558G0tF"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({d8gC7aPSu: {"data-framer-name": "310"}, i8MkIqnHE: {"data-framer-name": "BigBoy"}, QyphF_D8A: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-9j2rf3-container"} layoutDependency={layoutDependency} layoutId={"Ab_ZA1T5y-container"}><Embed height={"100%"} html={""} id={"Ab_ZA1T5y"} layoutId={"Ab_ZA1T5y"} style={{height: "100%", width: "100%"}} type={"url"} url={zOQouysXS} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MN4qU.framer-1ecg3ag, .framer-MN4qU .framer-1ecg3ag { display: block; }", ".framer-MN4qU.framer-zblopb { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 360px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 1018px; }", ".framer-MN4qU .framer-9j2rf3-container { aspect-ratio: 2.827777777777778 / 1; flex: none; height: 100%; position: relative; width: var(--framer-aspect-ratio-supported, 1018px); z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-MN4qU.framer-zblopb { gap: 0px; } .framer-MN4qU.framer-zblopb > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-MN4qU.framer-zblopb > :first-child { margin-left: 0px; } .framer-MN4qU.framer-zblopb > :last-child { margin-right: 0px; } }", ".framer-MN4qU.framer-v-579k0a.framer-zblopb { height: 400px; width: 310px; }", ".framer-MN4qU.framer-v-579k0a .framer-9j2rf3-container { aspect-ratio: unset; height: 400px; width: 310px; }", ".framer-MN4qU.framer-v-765lxn.framer-zblopb { height: 250px; width: 300px; }", ".framer-MN4qU.framer-v-765lxn .framer-9j2rf3-container { aspect-ratio: unset; height: 250px; width: 300px; }", ".framer-MN4qU.framer-v-tplo4j.framer-zblopb { aspect-ratio: 2.827777777777778 / 1; height: var(--framer-aspect-ratio-supported, 425px); width: 1200px; }", ".framer-MN4qU.framer-v-tplo4j .framer-9j2rf3-container { width: var(--framer-aspect-ratio-supported, 1200px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 360
 * @framerIntrinsicWidth 1018
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"d8gC7aPSu":{"layout":["fixed","fixed"]},"QyphF_D8A":{"layout":["fixed","fixed"]},"i8MkIqnHE":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"zOQouysXS":"auglurl"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPuyRk62h5: React.ComponentType<Props> = withCSS(Component, css, "framer-MN4qU") as typeof Component;
export default FramerPuyRk62h5;

FramerPuyRk62h5.displayName = "HeroAugl";

FramerPuyRk62h5.defaultProps = {height: 360, width: 1018};

addPropertyControls(FramerPuyRk62h5, {variant: {options: ["Vr558G0tF", "d8gC7aPSu", "QyphF_D8A", "i8MkIqnHE"], optionTitles: ["1018", "310", "Variant 3", "BigBoy"], title: "Variant", type: ControlType.Enum}, zOQouysXS: {defaultValue: "https://app.pulsmedia.is/bordi/10255/live?preview=true", title: "auglurl", type: ControlType.String}} as any)

addFonts(FramerPuyRk62h5, [{explicitInter: true, fonts: []}, ...EmbedFonts], {supportsExplicitInterCodegen: true})